import { template as template_ba023efd71a84cecad50b9330aab5ff2 } from "@ember/template-compiler";
const FKLabel = template_ba023efd71a84cecad50b9330aab5ff2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
